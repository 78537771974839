.payment-page-container {
    padding: 0 2rem;
    background-color: #ededed;
    min-height: 100vh;
    .payment-page-title{
        margin: 1rem 0;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 600;
    }
    .payment-page-content {
        border-radius: 1rem;
        // border: 1px solid black;
        padding: 0 2rem 1rem 2rem;

        .payment-page-content-left {

            .payment-page-content-left-top {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                // border: 1px solid black;
                border-radius: 1rem;
                padding: 1rem;
                background-color: #fff;

            }

            .payment-page-content-left-bottom {
                margin-top: 2rem;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                // border: 1px solid black;
                border-radius: 1rem;
                padding: 1rem;
                background-color: #fff;
            }
        }
    }
}