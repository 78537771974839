.home-page-container {
    min-height: 130vh;
    padding-bottom: 3rem;
    .container {
        .card-container {
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
        }

        .home-page-button-load-more {
            button {
                width: 18rem;
                border: none;
                color: #fff;
                padding: 0.7rem 0;
                background-color: #8EC5FC;
                background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                transition: 0.3s;

                &:hover {
                    opacity: 0.7;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                }
            }
        }
    }
}