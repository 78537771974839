.my-order-container {
    padding: 2rem 0;
    min-height: 100vh;
    background-color: #ededed;

    .my-order-title {
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 600;
        padding-left: 2rem;
    }

    .my-order-body {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
       
        .non-order{
            font-size: 2rem;
        }
        .my-order-body-wrapper {
            padding: 2rem;
            margin-top: 1rem;
            border-radius: 1rem;
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            min-width: 60vw;
            max-width: 60vw;
            .status {
                .status-title {
                    font-weight: 600;
                }

                .status-content {
                    padding: 1rem;

                    .delivery,
                    .payment {
                        font-weight: 600;

                        span {
                            color: rgb(255, 66, 78);
                        }
                    }
                }
            }

            .my-order-body-product {
                margin-top: 1.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #C5FFF8;
                border: 1px solid #7B66FF;
                border-radius: 1rem;
                padding: 0.5rem;

                .product {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;

                    .img {
                        width: 4rem;
                        height: 4rem;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 0.5rem;
                    }

                    .name {
                        text-transform: lowercase;
                        font-weight: 500;
                    }
                }

                .quantity {
                    // font-weight: bold;
                }

                .price {
                    font-weight: bold;
                }

                .buy-again{
                    cursor: pointer;
                    text-decoration: underline;
                    transition: 0.3s;
                    &:hover{
                        color: #7B66FF;
                    }
                }
            }

            .total-price {
                border-top: 1px solid #ccc;
                padding: 1rem 0;
                // margin-top: 2rem;
                display: flex;
                justify-content: space-between;
                gap: 0.5rem;
                font-weight: 600;
                align-items: center;
                margin-top: 1rem;
                .created {
                    font-size: 1.2rem;

                }



                .price {
                    font-size: 1.4rem;

                    span {
                        color: rgb(255, 66, 78);
                        font-size: 1.4rem;

                    }
                }
            }

            .my-order-footer {
                margin-top: 1rem;
                display: flex;
                gap: 0.5rem;
                justify-content: flex-end;
                .received{
                    background-color: rgb(255, 66, 78);
                    color: #fff;
                    &:hover{
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}