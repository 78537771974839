.cart-page-container {
    padding-top: 2rem;
    min-height: calc(100vh - 100px);
    background-color: #efefef;

    .cart-page-title {
        font-size: 2rem;
        font-weight: 500;
        line-height: 2.3rem;
        text-transform: uppercase;
        flex: 1 1 0%;
    }

    .cart-page-body {
        display: flex;
        justify-content: center;

        .cart-page-body-left {
            .cart-page-body-left-top {
                padding: 0.7rem;
                border-radius: 0.5rem;
                font-size: 1.2rem;
                
                background-color: #fff;
                color: rgb(56, 56, 61);
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                .cart-page-body-left-top-remove{
                    i{
                        transition: 0.3s;
                        font-size: 1.2rem;
                        cursor: pointer;
                        &:hover{
                            color: rgb(255, 66, 78);
                        }
                    }
                }
            }

            .cart-page-body-left-bottom {
                padding: 0.5rem;
                border-radius: 0.5rem;
                font-size: 1.4rem;
                background-color: #fff;
                color: rgb(56, 56, 61);
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                .cart-page-body-left-bottom-b {
                    .row {
                        .cart-page-body-left-bottom-b-product {
                            display: flex;
                            align-items: center;
                           
                            input {

                                margin-right: 0.5rem;
                            }

                            .cart-page-body-left-bottom-b-product-img {
                                width: 4rem;
                                height: 4rem;


                                background-image: url('../../assets/images/product/samsung-galaxy-zfold5-xanh-256gb-1-1.jpg');
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;
                            }

                            .cart-page-body-left-bottom-b-product-name {
                                font-size: 0.8rem;
                                font-weight: 400;
                                color: rgb(39, 39, 42);
                                display: -webkit-box;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                margin-left: 1rem;
                            }
                        }

                        .cart-page-body-left-bottom-b-price {
                            display: flex;
                            font-size: 1rem;
                            font-weight: 600;
                            color: rgb(39, 39, 42);
                            align-items: center;
                        }

                        .cart-page-body-left-bottom-b-amount {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .content {

                                button {
                                    height: 2.3rem;
                                    width: 2.3rem;
                                    border: 0.5px solid #ccc;
                                    background-color: transparent;
                                    transition: 0.3s;

                                    &:hover {
                                        background-color: rgba(0, 0, 0, 0.2);
                                    }

                                }

                                input {
                                    border: 0.5px solid #ccc;
                                    height: 2.3rem;
                                    width: 2.5rem;
                                }
                            }
                        }

                        .cart-page-body-left-bottom-b-total-price {
                            display: flex;
                            font-size: 1.2rem;
                            font-weight: 600;
                            color: rgb(255, 66, 78);
                            align-items: center;
                        }

                        .cart-page-body-left-bottom-b-remove {
                            display: flex;
                            align-items: center;

                            i {
                                font-size: 1.5rem;
                                transition: 0.3s;

                                &:hover {
                                    cursor: pointer;
                                    color: rgb(255, 66, 78);
                                }
                            }
                        }
                    }
                }
            }
        }

        .cart-page-body-right {
            margin-left: 2rem;

            
            .cart-page-body-right-middle {
                background-color: #fff;
                border-radius: 1rem;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                font-weight: 400;
                font-size: 1.2rem;
                color: rgb(51, 51, 51);

                .cart-page-body-right-middle-price {
                    display: flex;
                    flex-wrap: nowrap;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                    padding: 1rem;

                    .price-value {
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: rgb(51, 51, 51);
                    }
                }
                .cart-page-body-right-middle-discount{
                    display: flex;
                    flex-wrap: nowrap;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                    padding: 0 1.5rem 0.5rem 1.5rem;
                    border-bottom: 1px solid #ccc;
                    span{
                        font-size: 0.8rem;
                    }
                }
                .cart-page-body-right-middle-total {
                    display: flex;
                    position: relative;
                    flex-wrap: nowrap;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                    padding: 1rem;
                    padding-bottom: 2rem;

                    .total-value {
                        color: rgb(254, 56, 52);
                        font-size: 1.8rem;
                    }

                    .total-value-noted {
                        position: absolute;
                        bottom: 10%;
                        right: 5%;
                        font-weight: 300;
                        font-size: 1rem;
                    }
                }

            }
            .cart-page-body-right-bottom{
                .btn-buy{
                    height: 3rem;
                    border: none;
                    background-color: rgb(255, 66, 78);
                    color: #fff;
                    font-weight: 500;
                    border-radius: 0.4rem;
                    transition: 0.3s;
                    &:hover{
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}   