.slider-container {
    .slider-wrapper {
        .slider-content {
            background-color: #eaeaea;
            height: 20rem;
            width: 100rem;
            img{
                height: 20rem;
                
            }
        }
    }
}