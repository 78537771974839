.offcanvas {
    .offcanvas-header {
        background-color: #8EC5FC;
        background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);

        h5 {
            font-size: 1.3rem;
            font-weight: 600;
            color: #fff;
            text-transform: uppercase;
        }
    }

    .offcanvas-body {
        .offcanvas-body-top {
            border-bottom: 1px solid black;
            padding: 0 0 2rem 1rem;
            display: flex;

            .offcanvas-body-top-left {
                height: 7rem;
                width: 7rem;
                border-radius:50%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 3rem;
            }

            .offcanvas-body-top-right {
                .offcanvas-body-top-right-content {
                    font-size: 1.2rem;
                    text-align: justify;
                    font-weight: 500;
                }
            }
        }

        .offcanvas-body-bottom {
            .popover-content-button {
                flex-wrap: wrap;

                margin-top: 1rem;
                padding: 1rem 0;

                .btn {
                    margin-bottom: 0.3rem;
                    width: 21.4rem;
                    font-size: 1.1rem;
                    border: 1px solid #39A7FF;
                    transition: 0.3s;

                    &:hover {
                        font-weight: bold;
                        color: #fff;
                        background-color: #8EC5FC;
                        background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
                    }
                }
            }
        }
    }
}