* {
    // font-family: 'Dancing Script', cursive;
    // font-family: 'Open Sans', sans-serif;
    font-family: 'Bodoni Moda', serif;
    // font-family: 'Georgia', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
}

body {
    overflow: visible;
}