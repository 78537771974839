.order-detail-container {
    .modal-body {
        .user-info {
            .name-address-phone {
                text-align: center;
                border-bottom: 1px solid #ccc;
                padding: 1rem;

                .name,
                .address,
                .phone {
                    font-size: 1.2rem;
                    font-weight: 600;

                }

                .name {
                    border-right: 1px solid #ccc;
                    padding-right: 1rem;
                    margin-right: 1rem;
                }
            }
        }

        .list-product {
            padding: 1rem;
            border-bottom: 1px solid #ccc;

            .product {
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
                background-color: #98E4FF;
                padding: 0.5rem;
                border-radius: 1rem;
                border: 1px solid #687EFF;

                .name-img {
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;

                    .img {
                        width: 5rem;
                        height: 5rem;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        border-radius: 0.2rem;
                    }

                    .name {
                        font-size: 1.1rem;
                        font-weight: 500;
                    }
                }

                .price {
                    font-size: 1.1rem;
                    font-weight: 600;
                }

                .discount {
                    font-size: 1.1rem;
                    color: rgb(246, 145, 19);
                    font-weight: 600;
                }
            }
        }

        .total-create-at {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;

            .created-at,
            .total-price {
                font-size: 1.4rem;
                font-weight: 600;
                span {
                    font-size: 1.4rem;
                    color: rgb(246, 145, 19);
                    font-weight: 600;
                }
            }
        }
    }
}