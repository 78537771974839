.type-product-container{
    padding:0 0 2rem 0;
    // background-color: #ededed;
    .title{
        font-size: 1.5rem;
        font-weight: 600;
        margin-left: 1rem;
        // margin-bottom: 1rem;
    }
    .type-product-body{
        display: flex;
        justify-content: center;
        .type-product-body-content{
            display: flex;
            flex-wrap: wrap;
            justify-content: safe;
            gap: 1rem;
        }
    }
}