.card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
    // width: 10rem;
    .card-img-top {
        min-height: 11rem;
        // width: 6rem;
    }

    .card-body {
        border-top: 1px solid #ccc;
        min-height: 13rem;

        .card-name-product {
            font-size: 1rem;
            font-weight: 500;
            text-overflow: ellipsis;
            text-transform: capitalize;
            word-wrap: break-word;
        }
        .card-price{
            display: flex;
            align-items: center;
            .card-price-product{
                font-size: 1.2rem;
                margin-right: 0.5rem;
                color: #d0011b;
            }
            .card-discount {
                font-size: 0.7rem;
                border: 1px solid #fff;
                width: 5rem;
                font-weight: 600;
                border-radius: 0.3rem;
                color: #fff;
                padding: 0.2rem;
                background-color: rgb(246, 145, 19);
            }
        }

        .card-sold{
            i{
                color: yellow;
            }
            span{
                margin-left: 1rem;
                color: #ccc;
                font-weight: 500;
            }
        }

        .card-address-product{
            margin-top: 1rem;
            position: absolute;
            bottom: 3%;
            font-weight: 200;
            color: rgba(0,0,0,.65);
        }
    }
}