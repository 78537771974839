.nav-bar-container {

    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1rem 2rem;
    .navbar {
        margin-bottom: 2rem;
        .container-fluid {
            .navbar-collapse {
                font-size: 1.1rem;
                .navbar-nav {
                    .nav-item {
                        text-align: center;
                        border-right: 2px solid #ccc !important;
                        min-width: 5rem !important;
                        button{
                            font-weight: 500;
                            transition: 0.5s;
                            &:hover{
                                color: #39A7FF;
                                text-decoration: underline wavy;
                            }
                        }
                    }
                }

            }
        }
    }
}