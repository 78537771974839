.update-modal-user {

    .modal-lg {
        .modal-dialog-centered {
            .modal-header {
                background-color: #8EC5FC;
                background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
            }
            .modal-body{
                .modal-body-form-content{
                    .form-group{
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}