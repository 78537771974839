.product-detail-container {
    background-color: #fff;
    min-height: 50vh;
    border-radius: 1rem;

    .product-detail-content {
        padding: 1rem;

        .product-detail-content-left {
            border-right: 1px solid #ccc;

            .product-detail-content-left-img {
                background-image: url('../../assets/images/product/samsung-galaxy-zfold5-xanh-256gb-1-1.jpg');
                width: 20rem;
                height: 20rem;
                background-position: center;
                background-size: cover;
                margin-left: 2rem;
                background-repeat: no-repeat;
            }
        }

        .product-detail-content-right {
            padding-left: 3rem;

            .product-detail-content-right-name {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 1.5rem;
            }

            .product-detail-content-right-info {
                display: flex;

                .product-detail-content-right-rating-sold {
                    border-right: 1px solid #ccc;
                    padding: 0;

                    i {
                        color: yellow;
                        font-weight: 600;
                    }
                }

                .product-detail-content-right-ratted {
                    border-right: 1px solid #ccc;
                }
            }

            .product-detail-content-right-price {
                margin-top: 3rem;
                margin-left: 3rem;
                display: flex;
                align-items: center;

                h2 {
                    color: #d0011b;
                    font-size: 2.5rem;
                }

                .discount {
                    margin-left: 1rem;
                    border-radius: 0.3rem;

                    color: #fff;
                    font-weight: 600;
                    background-color: #ee4d2d;
                    padding: 0 0.5rem;
                }
            }

            .product-detail-content-right-delivery {
                display: flex;

                .title {
                    font-size: 1rem;
                    color: #777070;
                    font-weight: 500;
                }

                .delivery {
                    margin-left: 2rem;

                    .free-ship {
                        .free-ship-img {
                            height: 2rem;
                            width: 2rem;
                        }
                    }
                }
            }

            .product-detail-content-right-amount {
                display: flex;
                align-items: center;
                margin-top: 3rem;

                .amount-input {
                    display: flex;
                    margin-left: 3rem;
                    align-items: center;

                    input {
                        height: 2.5em;
                        width: 4rem;
                        text-align: center;
                    }

                    button {
                        height: 2.5rem;
                        width: 2.5rem;
                        font-size: 1.3rem;
                        font-weight: 600;
                        border: 1px solid #ccc;
                        background-color: #fff;
                        transition: 0.3s;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.2);
                        }
                    }
                }
            }

            .product-detail-content-right-buy {
                margin-top: 3rem;
                display: flex;
                justify-content: center;

                button {
                    border: 1px solid #ccc;
                    height: 3rem;
                    width: 20rem;
                    font-size: 1.4rem;
                    color: #fff;
                    font-weight: 600;
                    background-color: #8EC5FC;
                    background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
                    transition: 0.5s;

                    &:hover {
                        background-color: #8EC5FC;
                        background-image: linear-gradient(62deg, #E0C3FC 0%, #8EC5FC 100%);
                    }

                }
            }

            .product-detail-content-right-commit {
                display: flex;
                align-items: center;

                .col-4 {
                    img {
                        height: 1.5rem;
                        width: 1.5rem;
                    }
                }
            }
        }
    }
}

.product-detail-comment {
    background-color: #fff;
    border-radius: 1rem;
    margin-top: 2rem;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    display: flex;
    justify-content: center;
}