.total-price-component-container {
    margin-top: 1rem;

    .total-price-component-body {
        background-color: #fff;
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 1rem;

        .total-price-component-body-top {
            display: flex;
            justify-content: space-between;
            padding-bottom: 1rem;

            .text {
                .text-title {
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: rgb(56, 56, 61);
                }

                .text-product {
                    color: rgb(128, 128, 137);

                    span {
                        margin-left: 0.5rem;
                        font-weight: 500;
                        color: #8EC5FC;
                        cursor: pointer;
                    }
                }
            }

            .change-product {
                color: #8EC5FC;
                font-weight: bold;
                font-size: 1.1rem;
                cursor: pointer;
            }
        }

        .total-price-component-body-middle {
            padding: 1rem 0;
            border-top: 1px solid #ccc;
            color: rgb(128, 128, 137);
            border-bottom: 1px solid #ccc;

            .provisional-price {
                display: flex;
                justify-content: space-between;
            }

            .delivery-price {
                display: flex;
                justify-content: space-between;
            }
        }

        .total-price-component-body-bottom {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 1rem 0;
            color: rgb(128, 128, 137);

            .price {
                color: rgb(255, 66, 78);
                font-size: 2rem;
                font-weight: 500;
            }

            .text {
                font-size: 1.1rem;
            }

            .noted {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .total-price-component-body-btn {
            margin-top: 1rem;
            .btn-order {
                background-color: rgb(255, 66, 78);
                color: #fff;
                font-size: 1.2rem;
                transition: 0.3s;
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }
}