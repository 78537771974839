.admin-page-container{
    background-color: #ededed;
    min-height: 100vh;
    .admin-page-content{
        display: flex;
        .admin-page-content-left{}
        .admin-page-content-right{
            
        }
    }
}