.address-component-container{
    .cart-page-body-right-top {
                background-color: #fff;
                border-radius: 1rem;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                .title {
                    color: rgb(128, 128, 137);
                    font-weight: normal;
                    padding-top: 0.5rem;
                    font-size: 1.5rem;
                    margin-bottom: 0.5rem;
                }

                .user-info {
                    padding: 0.3rem;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;

                    .name {
                        border-right: 1px solid rgb(128, 128, 137);
                        width: 50%;
                    }

                    .phone {
                        width: 50%;
                        text-align: center;
                    }
                }

                .address {
                    padding: 1rem;
                    color: rgb(128, 128, 137);
                    font-weight: normal;
                    text-align: justify;

                    button {
                        color: rgb(0, 171, 86);
                        background-color: rgb(239, 255, 244);
                        padding: 0.5rem;
                    }
                }
            }

}