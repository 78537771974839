.spinner-border-container{
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 102;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner-border{
        width: 4rem;
        height: 4rem;
    }
    .spinner-border-text{
        font-size: 2rem;
    }
}