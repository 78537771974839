.input-search-container {
  position: relative;
  .input-group {
    border: 1px solid #000 !important;
    border-radius: 1rem;
    height: 3rem;

    input {
      border-radius: 1rem;
      background-color: #fff !important;
    }

    button {
      height: 3rem;
      border-radius: 1rem;
      outline: none !important;
      border: none !important;
      transition: 0.5s;

      &:hover {
        background-color: rgba(255, 2555, 255, 0.5);
      }
    }
  }

  .search-content {
    position: absolute;
    top: 110%;
    // top: 90%;
    // left: 25%;
    border-radius: 1rem;
    // min-height: 10rem;
    width: 37rem;
    background-color: #fff;
    padding: 1rem;
    ul{
      padding: 0 !important;
      li{
        display: flex;
        list-style-type: none;
        gap: 1rem;
        border-radius: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #ccc;
        padding: 0.5rem;
        align-items: center;
        cursor: pointer;
        &:hover{
          background-color: rgba(0, 0, 0, 0.2);
        }
        .search-content-img{
          width: 5rem;
          height: 2rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 0.3rem;
        }
        .search-content-name{
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
    .search-content-not-found{
      display: flex;
      justify-content: center;
      
    }
  }
}