.admin-order-container{
    padding-top: 2rem;
    .admin-order-title{
        font-size: 2rem;
        font-weight: 600;
        margin-left: 1rem;
    }
    .admin-order-body{
        margin-top: 2rem;
        .admin-order-table{}
    }

}