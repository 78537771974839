.product-component-container {
    border-top: 1px solid #ccc;
    padding-top: 1rem;
    
    .product-component-title {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
    }

    .product-component-content {
        padding: 1rem;

        .product-component-content-product {
            display: flex;
            font-size: 0.8rem;
            gap: 0.5rem;
            color: rgb(128, 128, 137);
            padding: 0.5rem;

            .product-component-content-product-img {
                width: 4rem;
                height: 4rem;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }


        }

        .product-component-content-price {
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .product-component-content-delivery {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.3rem;

            .content {
                background: rgb(245, 245, 250);
                padding: 0.5rem;
                border: 1px solid #ededed;
                border-radius: 0.3rem;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

            }
        }
    }
}