.payment-method-container {
    .payment-method-title {
        font-size: 1.2rem;
        font-weight: 600;
    }

    .payment-method-content {
        .form-group {
            padding: 1rem;
            background: rgb(240, 248, 255);
            border: 1px solid rgb(194, 225, 255);
            transition: 0.3;
            border-radius: 1rem;
            margin-bottom: 0.5rem;
            label {
                cursor: pointer;
            }

        }
    }
}